import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'

import { Layout } from '../components/Layout'
import { components } from '../slices'

const MainpageTemplate = ({ data }) => {
  // console.log('MainpageTemplate data: ', data)
  if (!data) return null

  const homepage = data.prismicMainpage || {}
  const seo = {
    title: homepage.data.seo_title,
    description: homepage.data.seo_description,
    keywords: homepage.data.seo_keywords,
  }
  console.log('seo: ', seo)

  const topMenu = data.prismicTopMenu || {}
  const footer = data.prismicFooter || {}

  const { lang, type, url } = homepage || {}
  const alternateLanguages = homepage.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  const allRestDetail = data.allPrismicRestaurantDetail || {}
  // console.log('allRestDetail: ', allRestDetail)
  const { totalCount, pageInfo, nodes } = allRestDetail
  const allEvents = data.allPrismicEvent
  // console.log('allPrismicEvent: ', allEvents)
  const allVideoblogs = data.allPrismicVbItem
  const allBlogs = data.allPrismicBlogItem
  // console.log('allPrismicEvent: ', allVideoblogs)
  const cntx = {rests: nodes, events: allEvents.nodes, videos: allVideoblogs.nodes, blogs:allBlogs.nodes}

  console.log("fixed_banner_link:", homepage.data.fixed_banner_link)

  return (
    <Layout topMenu={topMenu.data} activeDocMeta={activeDoc} footer={footer} seo={seo} >
        <div className="bg-dark d-flex flex-column" style={{backgroundImage:"url(" + homepage.data.cover_image.url + ")",backgroundPosition:"center center",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
          <div className="py-5" style={{backgroundColor:"rgba(0, 0, 0, 0.75)"}}>
            <div className="position-relative container py-5">
              <div className="py-5">
                <h1 className="text-center text-white">{homepage.data.title.text}</h1>
                <h2 className="text-center text-white mt-3">{homepage.data.desc.text}</h2>
              </div>
            </div>
          </div>
        </div>
        <a href={homepage.data.fixed_banner_link.url}><img className="position-fixed d-none d-xxl-block" style={{right:"24px",bottom:"60px",zIndex:"100"}} src={homepage.data.fixed_banner.url} alt="Wochenkarte und Tagesmenü Banner"/></a>
        <SliceZone slices={homepage.data.body} components={components} context={cntx} />
    </Layout>
  )
}

export const query = graphql`
  query mainpageQuery($lang: String) {
    prismicMainpage(lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {
        seo_title
        seo_description
        seo_keywords

        title {
          text
        }
        desc {
          text
        }
        cover_image {
          url
        }
        fixed_banner {
          url
        }
        fixed_banner_link {
          url
        }
        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...MainpageMainCategory
          ...MainpageMainWeeklyCard
          ...MainpageMainRestourants
          ...MainpageMainEvents
          ...MainpageMainVideoblog
          ...MainpageMainBlog
					...MainpageMainAboutUs
        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
    prismicFooter {
      ...FooterFragment
    }


    allPrismicRestaurantDetail(
      limit: 10
      skip: 0
    ){
      pageInfo {
        currentPage
        itemCount
        pageCount
        totalCount
      }
      totalCount
      nodes {
        _previewable
        id
        uid
        data {
          priority
          body {
            ... on PrismicRestaurantDetailDataBodyRestaurantDetail {
              id
              slice_type
              slice_label
              items {
                image {
                  url
                  dimensions {
                    width
                    height
                  }
                  fluid {
                    sizes
                    srcSet
                    src
                  }
                }
              }
              primary {
                rest_name
                phone
                email
                address
                description {
                  text
                  richText
                }
                logo {
                  url
                }
                sunday_time
                monday_time
                tuesday_time
                wednesday_time
                thursday_time
                friday_time
                saturday_time
                #categories
                american
                asian
                bar
                german
                greek
                indian
                international
                italian
                portuguese
                street_food
                sushi
                turkish
                vegan
              }
            }
          }
        }
      }
    }
  # events
  allPrismicEvent(
    sort: {fields: data___start_time, order: DESC}
    skip: 0
    # filter: {
		# 	data:{
    #     finish_time:{gte: $now}
    #   }
    # }
  ) {
    nodes {
      _previewable
      uid
      url
      data {
        title {
          text
        }
        start_time
        finish_time
        body {
          ... on PrismicEventDataBodyEventInfo {
            id
            slice_label
            slice_type
            items {
              ... on PrismicEventDataBodyEventInfoItem {
                image {
                  url
                }
              }
            }
            primary {
              ... on PrismicEventDataBodyEventInfoPrimary {
                description {
                  text
                }
              }
            }
          }
        }
      }
    }
    pageInfo {
      currentPage
      pageCount
    }
  }
  # videoblog
  allPrismicVbItem(
      sort: { fields: data___date, order: DESC }
      limit: 3
      skip: 0
    ) {
      nodes {
        uid
        url
        data {
          title {
            text
          }
          date
          body {
            ... on PrismicVbItemDataBodyVideoblogInfo {
              id
              slice_type
              primary {
                description {
                  richText
                }
                video_url {
                  thumbnail_url
                }
              }
            }
          }
        }
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
    #blog
    allPrismicBlogItem(
      sort: { fields: data___date, order: DESC }
      limit: 3
      skip: 0
    ) {
      nodes {
        uid
        url
        data {
          seo_title
          seo_description
          seo_keywords
          no_robots
          type
          title
          date
          body {
            ... on PrismicBlogItemDataBodyBlogInfo {
              id
              slice_type
              primary {
                img {
                  url
                }
                text {
                  text
                  richText
                }
              }
            }
            ... on PrismicBlogItemDataBodyRecipeInfo {
              id
              slice_type
              primary {
                img {
                  url
                }
                desc {
                  text
                  richText
                }
              }
            }
            ... on PrismicBlogItemDataBodyVideoblogInfo {
              id
              slice_type
              primary {
                desc {
                  richText
                  text
                }
                video_url {
                  embed_url
                  thumbnail_url
                }
              }
            }
          }
        }
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
`

export default withPrismicPreview(MainpageTemplate)
